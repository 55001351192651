import styled from 'styled-components';

export const AspectRatioBox = styled.div`
    /*  */
    /* background-color: lightblue; */
    @media (min-width: 1280px) {
        width: 100%;
        position: relative;
        padding-top: 32.64%;
    }
`;

export const RegistrationCompletedStyle = styled.div`
    @media (max-width: 1279.98px) {
        position: relative;
        z-index: 1;

        margin: 0 auto;
        max-width: 1144px;
    }

    @media (min-width: 1280px) {
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
    }

    @media (max-width: 767.98px) {
        padding-left: 32px;
        padding-right: 32px;
    }

    @media (min-width: 768px) {
        padding-left: 64px;
        padding-right: 64px;
    }

    .content {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        /* background-color: pink; */
        height: 100%;
        z-index: 2;
    }

    div.bg {
        @media (max-width: 1279.98px) {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;

            background-color: white;
            opacity: 0.85;
            z-index: -1;
        }
    }

    img.complete-logo {
        width: 114px;

        @media (max-width: 639.98px) {
            margin-top: 45px;
        }

        @media (max-width: 1365.98px) {
            width: 80px;
        }

        @media (min-width: 640px) and (max-width: 1365.98px) {
            margin-top: 4vw;
        }
    }

    h2.thank-you-header {
        font-size: 28px;

        @media (min-width: 1366px) {
            font-size: 40px;
        }

        font-weight: 900;
        color: #e03934;
    }

    p {
        max-width: 50%;
        font-size: 1.2vw;

        @media (max-width: 1279.98px) {
            font-size: 18px;
            max-width: 75%;
        }

        @media (min-width: 1660px) {
            font-size: 22px;
        }
    }

    .return {
        a {
            color: #1aaac2;
            font-size: 1.2vw;

            @media (max-width: 1279.98px) {
                font-size: 18px;
            }

            @media (min-width: 1660px) {
                font-size: 22px;
            }
        }

        margin-bottom: 5%;

        @media (max-width: 639.98px) {
            margin-bottom: 45px;
        }
    }
`;
