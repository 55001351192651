import { Image } from 'antd';

import { MainBannerWrapper } from './styled';

import main_banner from './main_banner.png';
import main_banner_lq from './main_banner_lq.jpeg';

const MainBannerSection = () => {
    return (
        <MainBannerWrapper>
            <Image
                src={main_banner}
                preview={false}
                width="100%"
                height="auto"
                placeholder={<Image src={main_banner_lq} preview={false} />}
                //
            />
        </MainBannerWrapper>
    );
};

export default MainBannerSection;
