import Slider from 'react-slick';
import { Col, Image } from 'antd';

import { OrganiserAndSponsorsBoothsStyle, MyRow } from './styled';

import booth1 from './main_1_positiveparenting.png';
import booth2 from './main_2_stemlife.png';
import booth3 from './main_3_suubalm.png';

import boothDutchLady from './booth_1_dutchlady.png';
import boothGSK from './booth_2_gsk.png';
import boothHappyFresh from './booth_3_happyfresh.png';
import boothMarigold from './booth_4_marigold.png';
import boothPfizer from './booth_5_pfizer.png';
import boothSanofiPasteur from './booth_6_sanofipasteur.png';

const settings = {
    speed: 300,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 3000,
    centerMode: true,
    centerPadding: '5%',
    className: 'center',
    customPaging: function (i) {
        return <button className={`slick-dot-${i}`}></button>;
    },
    slidesToShow: 4,
    slidesToScroll: 1
};

const spans1 = {
    xs: { span: 8, offset: 0 },
    lg: { span: 8, offset: 0 }
};

const spans2 = {
    xs: { span: 8, offset: 0 },
    lg: { span: 8, offset: 0 }
};

const OrganiserAndSponsorsBooths = () => {
    return (
        <OrganiserAndSponsorsBoothsStyle id="sponsors">
            <h2 className="section-header">Organiser and Sponsors' Booths</h2>

            <MyRow
                gutter={{
                    xs: 12,
                    sm: 20,
                    md: 28,
                    lg: 36
                }}
                align="bottom"
            >
                <Col {...spans1}>
                    <Image
                        src={booth1}
                        alt="Positive Parenting"
                        preview={false}
                        width="100%"
                        height="auto"
                        //
                    />
                </Col>

                <Col {...spans2}>
                    <Image
                        src={booth2}
                        alt="StemLife"
                        preview={false}
                        width="100%"
                        height="auto"
                        //
                    />
                </Col>

                <Col {...spans2}>
                    <Image
                        src={booth3}
                        alt="Suu Balm"
                        preview={false}
                        width="100%"
                        height="auto"
                        //
                    />
                </Col>
            </MyRow>

            <Slider {...settings}>
                <div>
                    <Image
                        src={boothDutchLady}
                        alt="Dutch Lady"
                        className="carousel-booth-img"
                        preview={false}
                        width="100%"
                        height="auto"
                        //
                    />
                </div>

                <div>
                    <Image
                        src={boothGSK}
                        alt="GlaxoSmithKline"
                        className="carousel-booth-img"
                        preview={false}
                        width="100%"
                        height="auto"
                        //
                    />
                </div>

                <div>
                    <Image
                        src={boothHappyFresh}
                        alt="HappyFresh"
                        className="carousel-booth-img"
                        preview={false}
                        width="100%"
                        height="auto"
                        //
                    />
                </div>

                <div>
                    <Image
                        src={boothMarigold}
                        alt="Marigold"
                        className="carousel-booth-img"
                        preview={false}
                        width="100%"
                        height="auto"
                        //
                    />
                </div>

                <div>
                    <Image
                        src={boothPfizer}
                        alt="Pfizer"
                        className="carousel-booth-img"
                        preview={false}
                        width="100%"
                        height="auto"
                        //
                    />
                </div>

                <div>
                    <Image
                        src={boothSanofiPasteur}
                        alt="Sanofi Pasteur"
                        className="carousel-booth-img"
                        preview={false}
                        width="100%"
                        height="auto"
                        //
                    />
                </div>
            </Slider>
        </OrganiserAndSponsorsBoothsStyle>
    );
};

export default OrganiserAndSponsorsBooths;
