import { useRef, useState } from 'react';
import ReactPixel from 'react-facebook-pixel';

import isEmail from 'validator/lib/isEmail';
import { Input, Radio, Checkbox, Button, Row, Col, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

import BackToTop from '../../../components/BackToTop/BackToTop';
import TermsModal from './TermsModal/TermsModal';

import { RegistrationMainWrapper, RegistrationForm as Form } from './styled';

const SPANS = {
    LABEL: {
        xs: { span: 24 },
        md: { span: 24 },
        lg: { span: 6 }
    },
    CONTROL: {
        xs: { span: 24 },
        md: { span: 24 },
        lg: { span: 18 }
    },
    LABEL_ALT: {
        xs: { span: 24 },
        md: { span: 24 },
        lg: { span: 8 }
    },
    CONTROL_ALT: {
        xs: { span: 24 },
        md: { span: 24 },
        lg: { span: 16 }
    }
};

const initialValidateStatus = {
    fullName: '',
    password: '',
    confirmPassword: '',
    nric: '',
    address: '',
    phone: '',
    email: '',
    gender: '',
    race: '',
    raceValue: '', // only for highlighting input border
    maritalStatus: '',
    householdChildren: '',
    householdIncome: '',
    terms: ''
};

const RegistrationMain = ({ register, isLoading }) => {
    const formRef = useRef();

    const fullNameRef = useRef();
    const nricRef = useRef();
    const addressRef = useRef();
    const phoneRef = useRef();
    const emailRef = useRef();
    const passwordRef = useRef();
    const confirmPasswordRef = useRef();
    const genderRef = useRef();
    const raceRef = useRef();
    const raceValueRef = useRef();
    const maritalStatusRef = useRef();
    const householdChildrenRef = useRef();
    const householdIncomeRef = useRef();
    const privacyPolicyRef = useRef();
    const dataProtectionRef = useRef();

    const [modalVisible, setModalVisible] = useState(false);
    const [raceValue, setRaceValue] = useState('');

    const [validateStatus, setValidateStatus] = useState({
        ...initialValidateStatus
    });

    const handleClose = () => {
        setModalVisible(false);
    };

    const onFinish = (values) => {
        if (isLoading) return;

        const {
            fullName,
            password,
            confirmPassword,
            nric,
            address,
            phone,
            email,
            gender,
            race,
            maritalStatus,
            householdChildren,
            householdIncome,
            privacyPolicy,
            dataProtection
        } = values;

        let focusElement;

        const _validateStatus = { ...initialValidateStatus };

        if (!fullName) {
            _validateStatus.fullName = 'Please enter your full name.';
            if (!focusElement) focusElement = fullNameRef;
        }

        if (!nric) {
            _validateStatus.nric = 'Please enter your NRIC number.';
            if (!focusElement) focusElement = nricRef;
        }

        if (!address) {
            _validateStatus.address = 'Please enter your mailing address.';
            if (!focusElement) focusElement = addressRef;
        }

        if (!phone) {
            _validateStatus.phone = 'Please enter your phone number.';
            if (!focusElement) focusElement = phoneRef;
        }

        if (!email || !isEmail(email)) {
            _validateStatus.email = 'Please enter a valid email address.';
            if (!focusElement) focusElement = emailRef;
        }

        if (!password) {
            _validateStatus.password = 'Please enter a password.';
            if (!focusElement) focusElement = passwordRef;
        }

        if (!confirmPassword) {
            _validateStatus.confirmPassword = 'Please re-enter the password.';
            if (!focusElement) focusElement = confirmPasswordRef;
        } else if (confirmPassword !== password) {
            _validateStatus.confirmPassword = 'Password does not match the above.';
            if (!focusElement) focusElement = confirmPasswordRef;
        }

        if (typeof gender !== 'number') {
            _validateStatus.gender = 'Please specify your gender.';
            if (!focusElement) focusElement = genderRef;
        }

        if (!race) {
            _validateStatus.race = 'Please specify your race.';
            if (!focusElement) focusElement = raceRef;
        }

        if (race === 'custom' && !raceValue) {
            _validateStatus.race = 'Please specify your race.';
            _validateStatus.raceValue = '.';
            if (!focusElement) focusElement = raceValueRef;
        }

        if (typeof maritalStatus !== 'number') {
            _validateStatus.maritalStatus = 'Please specify your marital status.';
            if (!focusElement) focusElement = maritalStatusRef;
        }

        if (typeof householdChildren !== 'number') {
            _validateStatus.householdChildren = 'Please specify the number of children in your household.';
            if (!focusElement) focusElement = householdChildrenRef;
        }

        if (typeof householdIncome !== 'number') {
            _validateStatus.householdIncome = 'Please specify your household income.';
            if (!focusElement) focusElement = householdIncomeRef;
        }

        if (!privacyPolicy) {
            _validateStatus.terms = 'You must agree to the terms before proceeding.';
            if (!focusElement) focusElement = privacyPolicyRef;
        }

        if (!dataProtection) {
            _validateStatus.terms = 'You must agree to the terms before proceeding.';
            if (!focusElement) focusElement = dataProtectionRef;
        }

        setValidateStatus(_validateStatus);

        let hasError = null;

        Object.keys(_validateStatus).forEach((key) => {
            if (_validateStatus[key]) hasError = true;
        });

        if (hasError) {
            if (focusElement && focusElement.current) {
                focusElement.current.focus();
            }

            return;
        }

        const data = {
            full_name: fullName,
            password,
            nric,
            address,
            phone,
            email,
            gender,
            race: race === 'custom' ? raceValue : race,
            marital_status: maritalStatus,
            household_children: householdChildren,
            household_income: householdIncome
        };

        // console.log(data);

        register(data);
        ReactPixel.track('SubmitApplication');
    };

    const clearValidateStatus = (fieldName) => {
        if (validateStatus[fieldName]) {
            setValidateStatus({
                ...validateStatus,
                [fieldName]: ''
            });
        }
    };

    return (
        <RegistrationMainWrapper>
            <Form ref={formRef} colon={false} onFinish={onFinish}>
                <div className="bg"></div>

                <div className="content">
                    <h2 className="form-header">Registration Form</h2>
                    <div className="form-header-separator"></div>

                    <Spin indicator={<LoadingOutlined style={{ fontSize: 80, color: '#1AAAC2' }} spin />} size="large" spinning={isLoading}>
                        <Row className="form-item-row input">
                            <Col {...SPANS.LABEL} className="label-col input">
                                <label htmlFor="fullName" title="Full Name">
                                    Full Name
                                </label>
                            </Col>

                            <Col {...SPANS.CONTROL} className="control-col main-input-col">
                                <Form.Item
                                    name="fullName"
                                    label={null}
                                    validateStatus={validateStatus.fullName ? 'error' : ''}
                                    help={validateStatus.fullName}
                                >
                                    <Input
                                        ref={fullNameRef}
                                        className="main-input"
                                        onChange={() => {
                                            clearValidateStatus('fullName');
                                        }}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row className="form-item-row input">
                            <Col {...SPANS.LABEL} className="label-col input">
                                <label htmlFor="nric" title="NRIC Number">
                                    NRIC Number
                                </label>
                            </Col>

                            <Col {...SPANS.CONTROL} className="control-col main-input-col">
                                <Form.Item
                                    name="nric"
                                    label={null}
                                    normalize={(val) => val.replace(/\D/g, '')}
                                    validateStatus={validateStatus.nric ? 'error' : ''}
                                    help={validateStatus.nric}
                                >
                                    <Input
                                        ref={nricRef}
                                        className="main-input"
                                        onChange={() => {
                                            clearValidateStatus('nric');
                                        }}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row className="form-item-row input">
                            <Col {...SPANS.LABEL} className="label-col input">
                                <label htmlFor="address" title="Mailing Address">
                                    Mailing Address
                                </label>
                            </Col>

                            <Col {...SPANS.CONTROL} className="control-col main-input-col">
                                <Form.Item
                                    name="address"
                                    label={null}
                                    validateStatus={validateStatus.address ? 'error' : ''}
                                    help={validateStatus.address}
                                >
                                    <Input
                                        ref={addressRef}
                                        className="main-input"
                                        onChange={() => {
                                            clearValidateStatus('address');
                                        }}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row className="form-item-row input">
                            <Col {...SPANS.LABEL} className="label-col input">
                                <label htmlFor="phone" title="Phone Number">
                                    Phone Number
                                </label>
                            </Col>

                            <Col {...SPANS.CONTROL} className="control-col main-input-col">
                                <Form.Item
                                    name="phone"
                                    label={null}
                                    normalize={(val) => val.replace(/\D/g, '')}
                                    validateStatus={validateStatus.phone ? 'error' : ''}
                                    help={validateStatus.phone}
                                >
                                    <Input
                                        ref={phoneRef}
                                        className="main-input"
                                        onChange={() => {
                                            clearValidateStatus('phone');
                                        }}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row className="form-item-row input">
                            <Col {...SPANS.LABEL} className="label-col input">
                                <label htmlFor="email" title="Email Address">
                                    Email Address
                                </label>
                            </Col>

                            <Col {...SPANS.CONTROL} className="control-col main-input-col">
                                <Form.Item
                                    name="email"
                                    label={null}
                                    validateStatus={validateStatus.email ? 'error' : ''}
                                    help={validateStatus.email}
                                >
                                    <Input
                                        ref={emailRef}
                                        className="main-input"
                                        onChange={() => {
                                            clearValidateStatus('email');
                                        }}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row className="form-item-row input">
                            <Col {...SPANS.LABEL} className="label-col input">
                                <label htmlFor="password" title="Password">
                                    Password
                                    {/* <Tooltip title="Set up a password which will be used to log onto the virtual event platform.">
                                        <QuestionCircleTwoTone style={{ marginLeft: 8, position: 'relative', top: 1, cursor: 'pointer' }} />
                                    </Tooltip> */}
                                </label>
                            </Col>

                            <Col {...SPANS.CONTROL} className="control-col main-input-col">
                                <Form.Item
                                    name="password"
                                    label={null}
                                    validateStatus={validateStatus.password ? 'error' : ''}
                                    help={validateStatus.password}
                                >
                                    <Input.Password
                                        ref={passwordRef}
                                        className="main-input"
                                        onChange={() => {
                                            clearValidateStatus('password');
                                        }}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row className="form-item-row input">
                            <Col {...SPANS.LABEL} className="label-col input">
                                <label htmlFor="confirmPassword" title="Confirm Password">
                                    Confirm Password
                                </label>
                            </Col>

                            <Col {...SPANS.CONTROL} className="control-col main-input-col">
                                <Form.Item
                                    name="confirmPassword"
                                    label={null}
                                    validateStatus={validateStatus.confirmPassword ? 'error' : ''}
                                    help={validateStatus.confirmPassword}
                                >
                                    <Input.Password
                                        ref={confirmPasswordRef}
                                        className="main-input"
                                        onChange={() => {
                                            clearValidateStatus('confirmPassword');
                                        }}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row className="form-item-row radio">
                            <Col {...SPANS.LABEL} className="label-col radio">
                                <label htmlFor="gender" title="Gender">
                                    Gender
                                </label>
                            </Col>

                            <Col {...SPANS.CONTROL} className="control-col main-radio-col">
                                <div className="radio-primary-wrapper">
                                    <Form.Item
                                        name="gender"
                                        label={null}
                                        validateStatus={validateStatus.gender ? 'error' : ''}
                                        help={validateStatus.gender}
                                    >
                                        <Radio.Group
                                            onChange={() => {
                                                clearValidateStatus('gender');
                                            }}
                                        >
                                            <div className="radio-group-wrapper">
                                                <div className="radio-option-wrapper">
                                                    <Radio value={0} ref={genderRef}>
                                                        Male
                                                    </Radio>
                                                </div>
                                                <div className="radio-option-wrapper">
                                                    <Radio value={1}>Female</Radio>
                                                </div>
                                            </div>
                                        </Radio.Group>
                                    </Form.Item>
                                </div>
                            </Col>
                        </Row>

                        <Row className="form-item-row radio">
                            <Col {...SPANS.LABEL} className="label-col race">
                                <label htmlFor="race" title="Race">
                                    Race
                                </label>
                            </Col>

                            <Col {...SPANS.CONTROL} className="control-col main-radio-col">
                                <div className="radio-primary-wrapper race">
                                    <Form.Item
                                        name="race"
                                        label={null}
                                        validateStatus={validateStatus.race ? 'error' : ''}
                                        help={validateStatus.race}
                                        className="form-item-race"
                                    >
                                        <Radio.Group
                                            className="race-radio-group"
                                            onChange={() => {
                                                clearValidateStatus('race');
                                            }}
                                        >
                                            <div className="radio-group-wrapper">
                                                <div className="radio-option-wrapper">
                                                    <Radio ref={raceRef} value="Malay">
                                                        Malay
                                                    </Radio>
                                                </div>
                                                <div className="radio-option-wrapper">
                                                    <Radio value="Chinese">Chinese</Radio>
                                                </div>
                                                <div className="radio-option-wrapper">
                                                    <Radio value="Indian">Indian</Radio>
                                                </div>

                                                <div className="radio-option-wrapper race-input">
                                                    <Radio
                                                        value="custom"
                                                        style={{
                                                            flexShrink: 0
                                                        }}
                                                    >
                                                        Others, please specify:
                                                    </Radio>

                                                    <div className="input-wrapper">
                                                        <Input
                                                            ref={raceValueRef}
                                                            value={raceValue}
                                                            onChange={(e) => {
                                                                setRaceValue(e.target.value);
                                                                clearValidateStatus('race');
                                                            }}
                                                            className={`main-input${validateStatus.raceValue ? ' unspecified-error' : ''}`}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </Radio.Group>
                                    </Form.Item>
                                </div>
                            </Col>
                        </Row>

                        <Row className="form-item-row radio">
                            <Col {...SPANS.LABEL} className="label-col radio">
                                <label htmlFor="maritalStatus" title="Marital Status">
                                    Marital Status
                                </label>
                            </Col>

                            <Col {...SPANS.CONTROL} className="control-col main-radio-col">
                                <div className="radio-primary-wrapper">
                                    <Form.Item
                                        name="maritalStatus"
                                        label={null}
                                        validateStatus={validateStatus.maritalStatus ? 'error' : ''}
                                        help={validateStatus.maritalStatus}
                                    >
                                        <Radio.Group
                                            onChange={() => {
                                                clearValidateStatus('maritalStatus');
                                            }}
                                        >
                                            <div className="radio-group-wrapper">
                                                <div className="radio-option-wrapper">
                                                    <Radio value={0} ref={maritalStatusRef}>
                                                        Single
                                                    </Radio>
                                                </div>

                                                <div className="radio-option-wrapper">
                                                    <Radio value={1}>Married</Radio>
                                                </div>

                                                {/* <div className="radio-option-wrapper">
                                                    <Radio value={2}>Divorced</Radio>
                                                </div>

                                                <div className="radio-option-wrapper">
                                                    <Radio value={3}>Widowed</Radio>
                                                </div> */}
                                            </div>
                                        </Radio.Group>
                                    </Form.Item>
                                </div>
                            </Col>
                        </Row>

                        <Row className="form-item-row radio no-of-children">
                            <Col {...SPANS.LABEL_ALT} className="label-col radio">
                                <label htmlFor="householdChildren" title="No. of child(ren) in your household">
                                    No. of child(ren) in your household
                                </label>
                            </Col>

                            <Col {...SPANS.CONTROL_ALT} className="control-col main-radio-col">
                                <div className="radio-primary-wrapper">
                                    <Form.Item
                                        name="householdChildren"
                                        label={null}
                                        validateStatus={validateStatus.householdChildren ? 'error' : ''}
                                        help={validateStatus.householdChildren}
                                    >
                                        <Radio.Group
                                            onChange={() => {
                                                clearValidateStatus('householdChildren');
                                            }}
                                        >
                                            <div className="radio-group-wrapper">
                                                <div className="radio-option-wrapper">
                                                    <Radio value={0} ref={householdChildrenRef}>
                                                        None
                                                    </Radio>
                                                </div>

                                                <div className="radio-option-wrapper">
                                                    <Radio value={1}>1-2</Radio>
                                                </div>

                                                <div className="radio-option-wrapper">
                                                    <Radio value={2}>3-4</Radio>
                                                </div>

                                                <div className="radio-option-wrapper">
                                                    <Radio value={3}>5 or more</Radio>
                                                </div>

                                                <div className="radio-option-wrapper">
                                                    <Radio value={4}>Currently expecting</Radio>
                                                </div>
                                            </div>
                                        </Radio.Group>
                                    </Form.Item>
                                </div>
                            </Col>
                        </Row>

                        <Row className="form-item-row radio">
                            <Col {...SPANS.LABEL} className="label-col radio">
                                <label htmlFor="householdIncome" title="Household Income">
                                    Household Income
                                </label>
                            </Col>

                            <Col {...SPANS.CONTROL} className="control-col main-radio-col">
                                <div className="radio-primary-wrapper">
                                    <Form.Item
                                        name="householdIncome"
                                        label={null}
                                        validateStatus={validateStatus.householdIncome ? 'error' : ''}
                                        help={validateStatus.householdIncome}
                                    >
                                        <Radio.Group
                                            onChange={() => {
                                                clearValidateStatus('householdIncome');
                                            }}
                                        >
                                            <div className="radio-group-wrapper">
                                                <div className="radio-option-wrapper">
                                                    <Radio value={0} ref={householdIncomeRef}>
                                                        Below RM4,000
                                                    </Radio>
                                                </div>

                                                <div className="radio-option-wrapper">
                                                    <Radio value={1}>RM4,001 to RM 9,000</Radio>
                                                </div>

                                                <div className="radio-option-wrapper">
                                                    <Radio value={2}>Above RM9,000</Radio>
                                                </div>
                                            </div>
                                        </Radio.Group>
                                    </Form.Item>
                                </div>
                            </Col>
                        </Row>

                        <Form.Item className="terms" name="privacyPolicy" label={null} labelCol={0} valuePropName="checked">
                            <Checkbox
                                ref={privacyPolicyRef}
                                onChange={() => {
                                    clearValidateStatus('terms');
                                }}
                            >
                                I have read and agree to the&nbsp;
                                <Button
                                    type="link"
                                    onClick={() => {
                                        setModalVisible(true);
                                    }}
                                >
                                    <span>Privacy Policy</span>
                                </Button>
                                .
                            </Checkbox>
                        </Form.Item>

                        <Form.Item
                            className="terms"
                            name="dataProtection"
                            label={null}
                            labelCol={0}
                            valuePropName="checked"
                            validateStatus={validateStatus.terms ? 'error' : ''}
                            help={validateStatus.terms}
                        >
                            <Checkbox
                                ref={dataProtectionRef}
                                onChange={() => {
                                    clearValidateStatus('terms');
                                }}
                            >
                                I consent to provide my data above, pursuant to the Personal Data Protection Act 2010.
                            </Checkbox>
                        </Form.Item>

                        <div>
                            <Button danger type="primary" className="submit" htmlType="submit" loading={isLoading}>
                                Submit
                            </Button>
                        </div>
                    </Spin>

                    <div className="back-to-top">
                        <BackToTop />
                    </div>
                </div>
            </Form>

            <TermsModal visible={modalVisible} handleClose={handleClose} />
        </RegistrationMainWrapper>
    );
};

export default RegistrationMain;
