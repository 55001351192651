import { Helmet } from 'react-helmet';
import ReactPixel from 'react-facebook-pixel';

import HowToParticipate from './HowToParticipate/HowToParticipate';
import ScheduleOfActivities from './ScheduleOfActivities/ScheduleOfActivities';
import OrganiserAndSponsorsBooths from './OrganiserAndSponsorsBooths/OrganiserAndSponsorsBooths';
import AboutUs from './AboutUs/AboutUs';
import Highlights from './Highlights/Highlights';

import { LandingStyles } from './styled';
import BackToTop from '../../components/BackToTop/BackToTop';

import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import MainBannerSection from './MainBannerSection/MainBannerSection';

const LandingPage = () => {
    const location = useLocation();

    // if linked from another page with hash, manually scroll to section
    // HashLink miscalculates scroll position due to react-slick initialization delay on mount
    useEffect(() => {
        if (location.hash) {
            const section = document.querySelector(location.hash);

            if (section) {
                const timer = () =>
                    setTimeout(() => {
                        section.scrollIntoView({ behavior: 'smooth' });
                    }, 200); // 200ms looks to be enough to accommodate slick initialization

                const timerId = timer();

                return () => {
                    clearTimeout(timerId);
                };
            }
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        const trackTimer = setTimeout(() => {
            ReactPixel.track('ViewContent', { content_name: 'LandingPage' });
        }, 500);

        return () => {
            clearTimeout(trackTimer);
        };
    }, []);

    return (
        <>
            <Helmet>
                <title>Joys of Parenting | Virtual Fair 2021</title>
            </Helmet>

            <LandingStyles>
                <MainBannerSection />

                <HowToParticipate />
                <AboutUs />
                <Highlights />
                <ScheduleOfActivities />
                <OrganiserAndSponsorsBooths />

                <div className="back-to-top">
                    <BackToTop />
                </div>
            </LandingStyles>
        </>
    );
};

export default LandingPage;
