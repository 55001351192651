import styled from 'styled-components';
import { Row } from 'antd';

export const PageFooterStyle = styled.div`
    background-color: #f0f4f9;

    padding-top: 36px;

    p.copyright {
        padding: 36px 0 24px;
        text-align: center;
        font-size: 12px;
    }

    @media (max-width: 1219.98px) {
        padding-left: 36px;
        padding-right: 36px;
    }
`;

export const FooterRow = styled(Row)`
    max-width: 1200px;
    margin: 0 auto;

    padding: 0 24px;

    @media (min-width: 768px) {
        padding: 0 12px;
    }

    div.notes {
        p {
            margin-bottom: 2px;
            font-size: 11px;

            &.title {
                font-weight: bold;
                font-size: 12px;
            }
        }

        div.disclaimer {
            margin-bottom: 24px;
        }
    }

    .about {
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        .header {
            font-size: 24px;
            font-weight: bold;
            color: #000;
            margin-bottom: 6px;

            @media (min-width: 680px) {
                font-size: 30px;
            }
        }

        .link {
            font-size: 26px;
            color: #52c7be;
        }

        .versacomm {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            font-size: 16px;
            text-align: left;
            margin-bottom: 4px;

            .logo {
                max-width: 204px;
                margin-bottom: 5px;
            }

            .main {
                font-weight: bold;
                margin-bottom: 6px;
                line-height: 1.2;
            }

            .sub {
                line-height: 1;
            }
        }

        .contact-info {
            margin-top: 6px;
            width: 100%;
            max-width: 330px;

            .group {
                display: flex;
                margin-top: 4px;

                div.img-container {
                    min-width: 30px;
                }

                span,
                a {
                    text-align: left;
                    margin-left: 6px;

                    &.adjust-top-1 {
                        position: relative;
                        top: 1px;
                    }

                    &.adjust-top-2 {
                        position: relative;
                        top: 2px;
                    }
                }

                .mailto {
                    width: 100%;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    overflow: hidden;
                }
            }
        }
    }

    .logos {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;

        @media (min-width: 992px) {
            margin-top: 24px;
        }

        .main {
            display: flex;
            justify-content: space-evenly;

            @media (max-width: 991.98px) {
                padding-top: 48px;
            }

            .container {
                &:first-of-type {
                    margin-right: 4%;
                }
            }

            .jop {
                max-width: 153px;
            }

            .pp {
                max-width: 157px;
            }
        }

        .footer-slider-container {
            margin-top: 48px;
        }
    }
`;
