import styled from 'styled-components';
import { Row } from 'antd';

export const OrganiserAndSponsorsBoothsStyle = styled.div`
    margin-top: 3vw;

    h2.section-header {
        margin-bottom: 3vw;

        font-size: 4vw;
        font-weight: 900;
        color: #e03934;
        text-align: center;
        text-transform: uppercase;

        @media (min-width: 1140px) {
            font-size: 45px;
        }
    }

    .ant-row {
        margin-right: 0 !important;
        margin-left: 0 !important;
    }

    .ant-col:first-child {
        padding-left: 0 !important;
    }

    .ant-col:last-child {
        padding-right: 0 !important;
    }

    .carousel-booth-img {
        cursor: grab;
    }
`;

export const MyRow = styled(Row)`
    padding-left: 8%;
    padding-right: 8%;

    @media (max-width: 767.97px) {
        padding-left: 18px;
        padding-right: 18px;
    }

    margin-bottom: 4vw;
`;
