import { HowToParticipateContainer } from './styled';

import { HashLink } from 'react-router-hash-link';
import { Image } from 'antd';

import how_to_participate from './how_to_participate.png';

const HowToParticipate = () => {
    return (
        <HowToParticipateContainer>
            <div style={{ position: 'relative' }}>
                <Image src={how_to_participate} preview={false} width="100%" height="auto" />

                <div
                    style={{
                        width: '100%',
                        height: '100%',
                        position: 'absolute',
                        top: 0,
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'space-between',
                        alignItems: 'center'
                    }}
                >
                    <span className="how-header">How to Participate</span>
                    <HashLink smooth to="/register#" className="register-anchor">
                        <button>REGISTER NOW</button>
                    </HashLink>
                </div>
            </div>
        </HowToParticipateContainer>
    );
};

export default HowToParticipate;
