import { Image } from 'antd';

import { AboutUsContainer } from './styled';

import about_us from './about_us.png';

const AboutUs = () => {
    return (
        <AboutUsContainer id="about">
            <h2 className="section-header">About Us</h2>

            <Image src={about_us} preview={false} width="100%" height="auto" />
        </AboutUsContainer>
    );
};

export default AboutUs;
