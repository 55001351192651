import { Modal, Button } from 'antd';

import { TermsWrapper, ModalTitle } from './styled';

const TermsModal = ({ visible, handleClose }) => {
    return (
        <Modal
            title={<ModalTitle>Privacy Policy</ModalTitle>}
            visible={visible}
            onCancel={handleClose}
            width={800}
            footer={null}
            centered
            destroyOnClose
        >
            <TermsWrapper>
                <div className="content">
                    <p className="header">Introduction</p>

                    <p>
                        Versacomm Sdn Bhd respects and upholds your rights to privacy protection in respect of the collection, use,
                        disclosure and handling of personal information. This Privacy Policy details the practices we have adopted to
                        protect your privacy, so that you can feel confident about how we manage the personal information you entrust to us.
                        By using our website(s) or otherwise providing VERSACOMM SDN BHD with personal information, you are consenting to
                        VERSACOMM SDN BHD on collecting, using, disclosing and/or transferring (including cross-border transfer) your
                        personal information in accordance with this Privacy Policy.
                    </p>

                    <p className="header">How we collect personal information</p>

                    <p>
                        Personal information is any information that can be used to identify you. VERSACOMM SDN BHD may collect information
                        about you (such as name, e-mail, phone number, date of birth, household income, or any other information, in the
                        following ways:
                    </p>

                    <ol>
                        <li>through our websites;</li>
                        <li>when you contact us to enquire or complain about our products;</li>
                        <li>when you submit ratings or reviews about our products/services; and/or</li>
                        <li>when you participate in our consumer promotions and market research.</li>
                    </ol>

                    <p className="header">Cookies</p>

                    <p>
                        Like many companies, we sometimes use "cookie" technology on our websites. Cookies are information stored on your
                        computer by your browser to save your preferences while you are visiting a particular website. When you log in, the
                        cookie tells us whether you visited us before or are a new visitor, and it enables us to learn which advertisements
                        bring users to our website. We use the cookie to help us identify site features in which you have the greatest
                        interest so that we can provide more of what you may want. If you choose, you can set your browser to reject cookies
                        or you can manually delete individual or all of the cookies on your computer by following your browser's help file
                        directions. However, if you do not enable cookies to be received by your web browser, you may have trouble accessing
                        some of the pages and certain features on this website.
                    </p>

                    <p className="header">Web beacons</p>

                    <p>
                        A web beacon is an electronic file on a Web page that allows us to count and recognize users who have visited that
                        page. Among other things, a web beacon can be used in HTML-formatted email to determine responses to our
                        communications and measure their effectiveness - for instance, if you get an email from us and click on a link in
                        that email to go to our website. You can generally avoid web beacons in email by turning off HTML display and
                        displaying only the text portion of your email.
                    </p>

                    <p className="header">How we use personal information</p>

                    <p>
                        Versacomm Sdn Bhd's primary goal in collecting personal information is to ensure that our consumers are satisfied
                        with our products and to provide our consumers with the best possible quality of customer service. Your personal
                        information is never shared, sold, rented or otherwise disclosed outside the company (except as required for any
                        statutory or legal requirements) in ways different from what is described in this Privacy Policy. We will take all
                        reasonable steps to maintain the security and integrity of your personal information including the use of computer
                        access passwords, lock-up cabinets, personnel policies and firewalls. VERSACOMM SDN BHD may use the personal
                        information you provide:
                    </p>

                    <ol>
                        <li>to communicate with you about our products and services;</li>
                        <li>to provide you with samples of our products and personalized promotional offers;</li>
                        <li>to allow you to participate in contests, promotions and surveys;</li>
                        <li>to address, investigate and monitor enquiries or complaints about our products and services;</li>
                        <li>for internal product development and quality control purposes;</li>
                        <li>to measure performance of marketing initiatives, advertising, and websites; and/or</li>
                        <li>to personalize, assess and improve our websites.</li>
                    </ol>

                    <p className="header">How we share personal information</p>

                    <p>
                        We may share information about you with third parties in certain circumstances including in the following
                        situations:
                    </p>

                    <ol>
                        <li>
                            Sharing Your Information with VERSACOMM SDN BHD group of companies. We may share information with VERSACOMM SDN
                            BHD group of companies, including our parent company, subsidiaries and affiliates.
                        </li>

                        <li>
                            Sharing Your Information with Partner Companies. We may share your information with carefully selected partner
                            companies when we believe their products or services may interest you. You may "opt out" of partner offers and
                            programs at any time by updating your User Profile (if available) or contacting us per the contact details
                            specified below. Our partners are prohibited from using your contact information for purposes other than
                            presenting relevant offers and programs, and they are required to maintain your contact information in
                            confidence.
                        </li>

                        <li>
                            Sharing Your Information with Service Providers. We may share information with companies or individuals that
                            provide us with services. These services may include, among other things, helping us to provide services that
                            you request, to create or maintain our databases, to research and analyze the people who request products,
                            services, or information from us, to prepare and distribute communications, or to respond to inquiries.
                        </li>

                        <li>
                            Interest-Based Advertising. VERSACOMM SDN BHD participates in interest-based (on-line behavior) advertising.
                            This means that a third party may use technology, such as a cookie on your browser or a web beacon, to identify
                            you on our website so that they can provide advertising about products and services tailored to your interest.
                            You may see advertising on our website tailored to how you browse or shop, or you may see advertising for
                            VERSACOMM SDN BHD products on other websites based on your browsing behavior across websites.
                        </li>

                        <li>
                            Blogs, Online Postings and Testimonials. We may decide to allow users to share comments, postings, testimonials
                            (including ratings or reviews), or other information. If you choose to submit such information to us, the
                            information that you submit may be available generally to the public. Information that you provide in these
                            areas may be read, collected, and used by others who access them.
                        </li>

                        <li>
                            Other Third Parties. We may share non-personal information with other third parties that are not described
                            above. When we do so we may aggregate or de-identify the information so that a third party would not be likely
                            to link data to you, your computer, or your device. Aggregation means that we combine the non-personal
                            information of numerous people together so that the data does not relate to any one person. De-identify means
                            that we attempt to remove or change certain pieces of information that might be used to link data to a
                            particular person.
                        </li>

                        <li>
                            Law Enforcement and Legal Process. We may report to law enforcement agencies any activities that we reasonably
                            believe to be unlawful, or that we reasonably believe may aid a law enforcement investigation into unlawful
                            activity. In addition, we reserve the right to release your information to law enforcement agencies if we
                            determine, in our sole judgment, that either you have violated our policies, or the release of your information
                            may protect the rights, property, or safety of VERSACOMM SDN BHD or another person.
                        </li>

                        <li>
                            Sharing Your Information as Permitted by Law. We may share your information with others as required by, or
                            permitted by, law. This may include sharing your information with government entities, or third parties in
                            response to subpoenas, court orders, other legal process, or as we believe is necessary to exercise our legal
                            rights, to defend against legal claims that have been brought against us, or to defend against possible legal
                            claims that we determine in our sole discretion might be brought against us.
                        </li>

                        <li>
                            Business Transactions. Circumstances may also arise where VERSACOMM SDN BHD decides to sell, buy, merge or
                            otherwise reorganize its business. Such a transaction may involve the disclosure of personal information to
                            prospective or actual buyers or sellers. It is our practice to seek appropriate protection for the disclosure of
                            such information in these types of transactions.
                        </li>

                        <li>
                            Cross-Border Transfers. Personal information may be transmitted to data storage facilities or servers located
                            outside Malaysia or to affiliates or trusted third parties based outside Malaysia so they may process personal
                            information on our behalf, and we can carry out your requests and use the personal information for the purpose
                            which it was collected.
                        </li>
                    </ol>

                    <p className="header">Content you submit on our websites</p>

                    <p>
                        By submitting content to and through website(s) controlled by VERSACOMM SDN BHD and/or its affiliates or service
                        providers ["VERSACOMM SDN BHD Sites"], you are responsible for the following: (1) you are the owner of the content;
                        (2) the content is truthful and accurate; (3) the content will not cause injury to any person or entity; (4) the
                        content does not infringe someone else's copyright, trademark, proprietary rights or rights of publicity and
                        privacy; (5) the content does not violate a law, statute, ordinance or regulation; (6) the content would not
                        reasonably be considered to be defamatory, libellous, hateful, racially or religiously biased or offensive or
                        harassing to another party; (7) the content does not contain references to other websites, email addresses, contact
                        information or phone numbers; (8) if the content contains the likeness or name of an identifiable person, you have
                        that person's permission to include their likeness/name in the content you are submitting; and (9) the content does
                        not contain computer viruses, worms or other potentially damaging programs or files.
                    </p>

                    <p>
                        For any content that you submit, you grant VERSACOMM SDN BHD perpetual, irrevocable, royalty-free, sub-licensable,
                        transferable right and license to use, copy, modify, delete in its entirety, adapt, publish, translate, create
                        derivative works from and/or sell and/or distribute such content and/or incorporate such content into any form,
                        medium or technology throughout the world without compensation to you. You also hereby grant each user of VERSACOMM
                        SDN BHD Sites a non-exclusive license to access your content through these sites, and to use, reproduce, distribute,
                        prepare derivative works of, display and perform such content as permitted through the functionality of these sites.
                    </p>

                    <p>
                        All content that you submit may be used at VERSACOMM SDN BHD’s sole discretion. VERSACOMM SDN BHD and/or its
                        affiliates reserve the right to change, condense or delete any content on VERSACOMM SDN BHD Sites that it deems, in
                        its sole discretion, to violate the content guidelines or any other provisions herein. VERSACOMM SDN BHD does not
                        guarantee that you will have any recourse through us to edit or delete any content you have submitted. Product
                        ratings and written comments are generally posted within two to four business days. However, VERSACOMM SDN BHD
                        reserves the right to remove or to refuse to post any submission for any reason.
                    </p>

                    <p className="header">Links</p>

                    <p>
                        Websites operated by us may contain links to other sites operated by third parties. VERSACOMM SDN BHD makes no
                        representations or warranties as to the privacy practices of any third-party site and is not responsible for the
                        privacy policies of other sites.
                    </p>

                    <p className="header">Accessing, correcting, updating or limiting processing of your personal information</p>

                    <p>
                        If your personal information changes (such as your address), or if you no longer wish us to keep your personal
                        information on file, we will endeavor to provide a way to correct, update or remove your records. We may charge you
                        a small fee to cover any administrative costs that we may incur in providing personal information to you.
                    </p>

                    <p>
                        If you believe for any reason that we are holding inaccurate or incomplete information about you, you may ask us to
                        correct it. You may also opt out of receiving marketing communications we send to you about our products and
                        services. However, please note that when you do so, it may affect the services you have chosen to receive from
                        VERSACOMM SDN BHD where accepting the communications is a condition of receiving the services.
                    </p>

                    <p>
                        If you wish to access your personal information or if you have any questions about our Privacy Policy, or any
                        complaint regarding treatment of your privacy by us, please contact:
                    </p>

                    <p>Contact: +603- 5632 3301 (Weekdays, 9am - 5pm)</p>

                    <p className="header">Changes to this Privacy Policy</p>

                    <p>
                        We will use personal information you provide in accordance with the Privacy Policy under which the information was
                        collected. If we decide to change our Privacy Policy, we will post the changes on our websites so you are always
                        aware of what information we collect, how we use it, and under what circumstances, if any, we disclose it.
                    </p>
                </div>

                <div className="footer">
                    <Button onClick={handleClose}>Close</Button>
                </div>
            </TermsWrapper>
        </Modal>
    );
};

export default TermsModal;
