import styled from 'styled-components';

import flower from './flower.png';
import wave from './wave.png';

export const ScheduleContainer = styled.div`
    padding-top: 8vw;

    background-repeat: no-repeat;
    background-image: url(${wave});
    background-size: 100%;

    @media (max-width: 991.98px) {
        background-position: 0px 7vw;
    }

    @media (min-width: 992px) and (max-width: 1399.98px) {
        background-position: 0px 4.5vw;
        padding-top: 6vw;
    }

    @media (min-width: 1400px) {
        background-position: 0px 2.5vw;
        padding-top: 5vw;
    }

    h2.section-header {
        margin-bottom: 3vw;

        font-size: 4vw;
        font-weight: 900;
        color: #e03934;
        text-align: center;
        text-transform: uppercase;

        @media (min-width: 1140px) {
            font-size: 45px;
        }

        ::after {
            background-image: url(${flower});
            background-repeat: no-repeat;
            background-size: contain;
            display: inline-block;
            content: '';
            position: absolute;

            max-width: 70px;

            @media (max-width: 1199.98px) {
                width: 4%;
                height: 4%;
                transform: translate(60%, 15%);
            }

            @media (min-width: 1200px) {
                transform: translate(40px, 8px);
                width: 53px;
                height: 50px;
            }
        }
    }

    .slick-dots {
        li {
            width: calc(min(1200px, 90vw) / 2 - 24px);
            background-color: #1aaac2;
            margin: 0;
            height: 5px;

            button {
                width: 100%;
                height: 5px;

                ::before {
                    content: '';
                    color: transparent;
                    width: 100%;
                    height: 5px;
                }
            }

            &.slick-active ::before {
                content: '';
                width: 100%;
                height: 7px;
                background-color: #e03934;
                transform: translateY(-1px);
                border-radius: 25px;
                transition: all 0.1s ease-in-out;
            }

            &:first-of-type {
                ::before {
                    content: '';
                    background-color: #1aaac2;
                    width: 50px;
                    height: 5px;
                    position: absolute;
                    left: -14px;
                    top: 0;
                    border-radius: 25px;
                    z-index: -1;
                }
            }

            &:last-of-type {
                ::after {
                    content: '';
                    background-color: #1aaac2;
                    width: 50px;
                    height: 5px;
                    position: absolute;
                    right: -14px;
                    top: 0;
                    border-radius: 25px;
                    z-index: -1;
                }
            }
        }
    }
`;
