import { StyledHashLink } from './styled';
import upArrow from './up-arrow.png';

const BackToTop = () => {
    return (
        <StyledHashLink smooth to="#">
            <img
                src={upArrow}
                alt="Up Arrow"
                width="100%"
                height="auto"
                //
            />
            <span>Back to Top</span>
        </StyledHashLink>
    );
};

export default BackToTop;
