import Slider from 'react-slick';
import { Image } from 'antd';

import { HighlightsContainer } from './styled';

import highlight_1 from './highlight_1.png';
import highlight_2 from './highlight_2.png';
import highlight_3 from './highlight_3.png';
import highlight_4 from './highlight_4.png';
import highlight_5 from './highlight_5.png';

const settings = {
    dots: true,
    dotsClass: 'slick-dots slick-thumb',
    fade: false,
    speed: 500,
    arrows: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    customPaging: function (i) {
        return <button className={`slick-dot-${i}`}></button>;
    }
};

const Highlights = () => {
    return (
        <HighlightsContainer id="highlights">
            <h2 className="section-header">Highlights</h2>

            <Slider {...settings}>
                <div>
                    <Image src={highlight_1} preview={false} width="100%" height="auto" />
                </div>

                <div>
                    <Image src={highlight_2} preview={false} width="100%" height="auto" />
                </div>

                <div>
                    <Image src={highlight_3} preview={false} width="100%" height="auto" />
                </div>

                <div>
                    <Image src={highlight_4} preview={false} width="100%" height="auto" />
                </div>

                <div>
                    <Image src={highlight_5} preview={false} width="100%" height="auto" />
                </div>
            </Slider>
        </HighlightsContainer>
    );
};

export default Highlights;
