import { useEffect } from 'react';
import { HashLink } from 'react-router-hash-link';
import { Image } from 'antd';

import completeLogo from './complete-logo.png';

import { AspectRatioBox, RegistrationCompletedStyle } from './styled';

const RegistrationCompleted = ({ topRef }) => {
    useEffect(() => {
        if (topRef) {
            topRef.current.scrollIntoView({
                behavior: 'smooth'
            });
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            <AspectRatioBox />

            <RegistrationCompletedStyle>
                <div className="bg" />

                <div className="content">
                    <Image className="complete-logo" src={completeLogo} preview={false} />

                    <h2 className="thank-you-header">THANK YOU FOR REGISTERING WITH US!</h2>

                    <p>
                        You’ve completed your online registration. Please check your mailbox for the event link and watch this space as
                        we’ll update you with more exciting activities.
                    </p>

                    <p>See you soon!</p>

                    <div className="return">
                        <HashLink smooth to="/#">
                            Return to Home
                        </HashLink>
                    </div>
                </div>
            </RegistrationCompletedStyle>
        </>
    );
};

export default RegistrationCompleted;
