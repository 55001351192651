import { useState } from 'react';

import { Button } from 'antd';
import { MenuOutlined, CloseOutlined } from '@ant-design/icons';
import { PageHeaderStyle, HeaderLink, Overlay } from './styled';
import { HashLink } from 'react-router-hash-link';

import CountdownTimer from './CountdownTimer/CountdownTimer';
import logo from './jop_logo@2x.png';

const PageHeader = ({ topRef }) => {
    const [showOverlay, setShowOverlay] = useState(false);

    const hideOverlay = () => {
        setShowOverlay(false);
    };

    return (
        <PageHeaderStyle ref={topRef}>
            <div className="nav">
                <img className="logo" src={logo} alt="Main Logo" />

                <HeaderLink smooth to="/#about">
                    About Us
                </HeaderLink>

                <HeaderLink smooth to="/#highlights">
                    Highlights
                </HeaderLink>

                <HeaderLink smooth to="/#sponsors">
                    Our Sponsors
                </HeaderLink>

                <HeaderLink smooth to="/#schedule">
                    Schedule of Activities
                </HeaderLink>
            </div>

            <div className="mobile-nav">
                <img className="logo" src={logo} alt="Main Logo" />

                <Button
                    size="large"
                    type="text"
                    icon={<MenuOutlined />}
                    onClick={() => {
                        setShowOverlay(true);
                    }}
                ></Button>
            </div>

            <div>
                <CountdownTimer />
            </div>

            <Overlay showOverlay={showOverlay}>
                <Button
                    className="close"
                    type="text"
                    size="large"
                    icon={<CloseOutlined />}
                    onClick={() => {
                        setShowOverlay(false);
                    }}
                ></Button>

                <div className="content">
                    <HashLink smooth to="/#about" onClick={hideOverlay}>
                        About Us
                    </HashLink>

                    <HashLink smooth to="/#highlights" onClick={hideOverlay}>
                        Highlights
                    </HashLink>

                    <HashLink smooth to="/#sponsors" onClick={hideOverlay}>
                        Our Sponsors
                    </HashLink>

                    <HashLink smooth to="/#schedule" onClick={hideOverlay}>
                        Schedule of Activities
                    </HashLink>
                </div>
            </Overlay>
        </PageHeaderStyle>
    );
};

export default PageHeader;
