import styled from 'styled-components';
import bg from './bg.png';

export const RegistrationPageStyle = styled.div`
    position: relative;
    text-align: center;
    background-repeat: no-repeat;
    background-image: url(${bg});
    background-size: 100%;
    background-position: 0px 0px;
`;
