import React, { useEffect, useRef } from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import ReactPixel from 'react-facebook-pixel';

import PageHeader from './shared/Layout/PageHeader/PageHeader';
import PageFooter from './shared/Layout/PageFooter/PageFooter';

import LandingPage from './pages/LandingPage/LandingPage';
import RegistrationPage from './pages/RegistrationPage/RegistrationPage';

import './App.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const App = () => {
    const topRef = useRef();

    useEffect(() => {
        const options = {
            autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
            debug: false // enable logs
        };

        ReactPixel.init('864680577530223', undefined, options);
        ReactPixel.pageView();
    }, []);

    return (
        <div className="App">
            <Router>
                <PageHeader topRef={topRef} />

                <Switch>
                    <Route exact path="/">
                        <LandingPage />
                    </Route>

                    <Route exact path="/register">
                        <RegistrationPage topRef={topRef} />
                    </Route>

                    <Route path="*">
                        <Redirect to="/" />
                    </Route>
                </Switch>

                <PageFooter />
            </Router>
        </div>
    );
};

export default App;
