import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Helmet } from 'react-helmet';
import ReactPixel from 'react-facebook-pixel';

import RegistrationMain from './RegistrationMain/RegistrationMain';
import RegistrationCompleted from './RegistrationCompleted/RegistrationCompleted';

import { RegistrationPageStyle } from './styled';
import { notification } from 'antd';

const RegistrationPage = ({ topRef }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [hasCompleted, setHasCompleted] = useState(false);

    const register = async (data) => {
        setIsLoading(true);

        axios
            .post('https://jop2021.herokuapp.com/api/register', data)
            .then(() => {
                setIsLoading(false);
                setHasCompleted(true);

                ReactPixel.track('CompleteRegistration');
            })
            .catch((error) => {
                const description = error?.response?.data?.message || error?.message || 'An unexpected error occurred.';

                notification.error({
                    message: 'Registration Failed',
                    description
                });

                setIsLoading(false);
            });
    };

    useEffect(() => {
        const trackTimer = setTimeout(() => {
            ReactPixel.track('ViewContent', { content_name: 'RegistrationPage' });
        }, 500);

        return () => {
            clearTimeout(trackTimer);
        };
    }, []);

    return (
        <>
            <Helmet>
                <title>Registration - Joys of Parenting | Virtual Fair 2021</title>
            </Helmet>

            <RegistrationPageStyle>
                {!hasCompleted && <RegistrationMain isLoading={isLoading} register={register} topRef={topRef} />}
                {hasCompleted && <RegistrationCompleted topRef={topRef} />}
            </RegistrationPageStyle>
        </>
    );
};

export default RegistrationPage;
