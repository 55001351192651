import styled from 'styled-components';

export const TermsWrapper = styled.div`
    .content {
        max-width: 800px;
        max-height: 60vh;

        padding: 0 12px 12px;
        overflow-y: auto;

        p.header {
            font-size: 18px;
            font-weight: bold;
        }

        ol {
            padding-inline-start: 30px;
        }
    }

    .footer {
        display: flex;
        flex-direction: row-reverse;

        button {
            margin-top: 20px;
            margin-right: 12px;
        }
    }

    font-family: 'Lato', sans-serif;
`;

export const ModalTitle = styled.span`
    font-weight: bold;
    font-size: 22px;

    font-family: 'Lato', sans-serif;
`;
