import { Image } from 'antd';

import { ScheduleContainer } from './styled';

import live_schedule from './live_schedule.png';

const ScheduleOfActivities = () => {
    return (
        <ScheduleContainer id="schedule">
            <h2 className="section-header">Schedule Of Activities</h2>

            <Image src={live_schedule} preview={false} width="100%" height="auto" />
        </ScheduleContainer>
    );
};

export default ScheduleOfActivities;
