import React from 'react';
import { Col, Image } from 'antd';
import Slider from 'react-slick';
import ReactPixel from 'react-facebook-pixel';

import { PageFooterStyle, FooterRow } from './styled';

import versacommLogo from './versacomm@2x.png';
import addressIcon from './contact_icon_address.png';
import phoneIcon from './contact_icon_phone.png';
import emailIcon from './contact_icon_email.png';

import jopLogo from './footer_logo_jop@2x.png';
import ppLogo from './footer_logo_pp@2x.png';
import footer_collaborators from './footer_collaborators@2x.png';
import footer_sponsors from './footer_sponsors@2x.png';

const spans = {
    first: {
        md: { span: 24 },
        lg: { span: 10 }
    },
    second: {
        md: { span: 24 },
        lg: { span: 14 }
    }
};

const settings = {
    dots: false,
    fade: true,
    speed: 300,
    arrows: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    pauseOnHover: false
};

const PageFooter = () => {
    return (
        <PageFooterStyle>
            <FooterRow align="bottom">
                <Col {...spans.first} className="about">
                    <div className="notes">
                        <div className="disclaimer">
                            <p className="title">Disclaimer:</p>
                            <p>
                                Positive Parenting (PP) educational materials are not in any way intended to substitute medical advice from
                                your doctor or health professionals. When in doubt, please consult your doctor.
                            </p>
                            <p>
                                The Malaysian Paediatric Association (MPA) and the other collaborating expert partners are not liable for
                                any issue arising from the use of these educational materials.
                            </p>
                            <p>
                                MPA and its collaboborating expert partners do not endorse any products and are not responsible for any
                                claims made in the advertisements, educational materials by the Sponsors in this virtual fair.
                            </p>
                            <p>
                                Intellectual property is vested in the PP programme and/or VersaComm Sdn Bhd. No part of the educational
                                materials shall be reprinted without written permission of the Chairman of the Positive Parenting Management
                                Committee.
                            </p>
                        </div>
                    </div>

                    <h2 className="header">Contact Us</h2>

                    <div className="versacomm">
                        <span className="main">Positive Parenting Secretariat</span>

                        <Image
                            src={versacommLogo}
                            alt="Versacomm"
                            className="logo"
                            placeholder={false}
                            preview={false}
                            width="100%"
                            height="auto"
                            //
                        />

                        <span className="sub">Versacomm Sdn Bhd</span>
                    </div>

                    <div className="contact-info">
                        <div className="group">
                            <div className="img-container">
                                <img src={addressIcon} alt="Address" />
                            </div>

                            <span>12A, Jalan PJS 8/4, Mentari Plaza, Bandar Sunway, 46150 Petaling Jaya, Selangor.</span>
                        </div>

                        <div className="group">
                            <div className="img-container">
                                <img src={phoneIcon} alt="Contact Number" />
                            </div>
                            <span className="adjust-top-2">011-6428 2861 / 03-5632 3301</span>
                        </div>

                        <div className="group">
                            <div className="img-container">
                                <img src={emailIcon} alt="Email Address" />
                            </div>

                            <a
                                className="adjust-top-1 mailto"
                                href="mailto:parentcare@mypositiveparenting.org"
                                onClick={() => {
                                    ReactPixel.track('Contact');
                                }}
                            >
                                parentcare@mypositiveparenting.org
                            </a>
                        </div>
                    </div>
                </Col>

                <Col {...spans.second} className="logos">
                    <div className="main">
                        <div className="container">
                            <Image
                                src={jopLogo}
                                alt="Joys of Parenting"
                                className="jop"
                                placeholder={false}
                                preview={false}
                                width="100%"
                                height="auto"
                                //
                            />
                        </div>
                        <div className="container">
                            <Image
                                src={ppLogo}
                                alt="Positive Parenting"
                                className="pp"
                                placeholder={false}
                                preview={false}
                                width="100%"
                                height="auto"
                                //
                            />
                        </div>
                    </div>

                    <div className="footer-slider-container">
                        <Slider {...settings}>
                            <div>
                                <Image
                                    src={footer_sponsors}
                                    alt="Our Sponsors"
                                    placeholder={false}
                                    preview={false}
                                    width="100%"
                                    height="auto"
                                    //
                                />
                            </div>

                            <div>
                                <Image
                                    src={footer_collaborators}
                                    alt="Our Collaborators"
                                    placeholder={false}
                                    preview={false}
                                    width="100%"
                                    height="auto"
                                    //
                                />
                            </div>
                        </Slider>
                    </div>
                </Col>
            </FooterRow>
            <p className="copyright">© Joys of Parenting 2021</p>
        </PageFooterStyle>
    );
};

export default PageFooter;
