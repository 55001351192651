import styled from 'styled-components';

import wave from './wave.png';
import flower from './flower.png';

export const AboutUsContainer = styled.div`
    margin-top: 3vw;

    background-repeat: no-repeat;
    background-image: url(${wave});
    background-size: 100%;

    h2.section-header {
        font-size: 4vw;
        font-weight: 900;
        color: #e03934;
        text-align: center;
        text-transform: uppercase;

        @media (min-width: 1140px) {
            font-size: 45px;
        }

        ::after {
            background-image: url(${flower});
            background-repeat: no-repeat;
            background-size: contain;
            display: inline-block;
            content: '';
            position: absolute;

            max-width: 70px;

            @media (max-width: 1199.98px) {
                width: 5%;
                height: 5%;
                transform: translate(60%, -20%);
            }

            @media (min-width: 1200px) {
                transform: translate(40px, -20px);
                width: 70px;
                height: 66px;
            }
        }
    }
`;
