import styled from 'styled-components';

export const TimerWrapper = styled.div`
    background-color: #011b3a;
    display: flex;
    justify-content: center;
    align-items: center;

    @media (max-width: 767.98px) {
        flex-direction: column-reverse;
        padding-top: 12px;
    }

    @media (min-width: 768px) {
    }

    padding: 24px 24px;
`;

export const Timer = styled.div`
    color: white;
    display: flex;
    align-items: center;

    .time-card {
        @media (min-width: 768px) {
            margin: 10px;
            width: 80px;
        }

        @media (max-width: 767.98px) {
            width: 60px;
        }

        text-align: center;

        .time-digit {
            font-weight: bold;
            margin-bottom: 0;
            color: white;

            @media (max-width: 413.98px) {
                font-size: 30px;
            }

            @media (min-width: 414px) and (max-width: 767.98px) {
                font-size: 40px;
            }

            @media (min-width: 768px) {
                font-size: 60px;
            }
        }

        .time-interval {
            text-transform: capitalize;
            position: relative;
            top: -12px;

            @media (max-width: 767.98px) {
                font-size: 12px;
            }
        }
    }

    .time-separator {
        font-size: 24px;
        position: relative;
        top: -15px;

        @media (min-width: 768px) {
            margin-left: 5px;
            margin-right: 5px;
        }
    }
`;

export const LongRegisterButton = styled.button`
    font-size: 24px;
    padding: 2px 40px;

    border-radius: 12px;
    outline: none;
    border: none;
    cursor: pointer;

    color: white;
    font-weight: bold;
    background: linear-gradient(0deg, #ed1c24 50%, #ba1319 50%);

    @media (max-width: 767.98px) {
        font-size: 3vw;
        padding: 2px 6vw;
    }

    @media (min-width: 768px) and (max-width: 1023.98px) {
        max-width: 250px;
        margin-right: 20px;
    }

    @media (min-width: 1024px) {
        margin-right: 50px;
    }

    &:hover {
        background: linear-gradient(0deg, #ba1319 50%, #ed1c24 50%);
    }
`;
