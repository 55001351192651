import styled from 'styled-components';

import { Form } from 'antd';

export const RegistrationMainWrapper = styled.div`
    color: pink;
    max-width: 1144px;
    margin: 0 auto;
`;

export const RegistrationForm = styled(Form)`
    max-width: 1144px;
    margin: 0 auto;

    @media (max-width: 767.98px) {
        padding-left: 32px;
        padding-right: 32px;
    }

    padding-left: 80px;
    padding-right: 80px;

    .ant-form-item {
        margin-bottom: 0px;

        &.terms {
            margin-top: 6px;
        }
    }

    .form-item-row {
        margin-bottom: 12px;

        &.input {
            min-height: 50px;
            margin-bottom: 4px;

            @media (max-width: 991.98px) {
                margin-bottom: 0;
            }
        }

        &.radio {
            min-height: 40px;
            margin-bottom: 0px;
        }

        &.no-of-children {
            margin-top: 6px;
            margin-bottom: 6px;
        }
    }

    .ant-form-item-explain.ant-form-item-explain-error {
        font-size: 13px;

        @media (min-width: 550px) {
            font-size: 14px;
        }
    }

    .label-col {
        text-align: left;
        display: flex;

        @media (max-width: 991.98px) {
            margin-top: 12px;
            margin-bottom: 6px;
        }

        @media (min-width: 992px) {
            min-height: 40px;
        }

        label {
            font-size: 3.5vw;

            @media (max-width: 419.98px) {
                font-size: 14px;
            }

            @media (min-width: 650px) {
                font-size: 20px;
            }
        }

        &.input {
            label {
                @media (min-width: 992px) {
                    position: relative;
                    top: 12px;
                }
            }
        }

        &.radio {
            label {
                @media (min-width: 992px) {
                    position: relative;
                    top: 2px;
                }
            }
        }

        &.race {
            label {
                @media (min-width: 992px) {
                    position: relative;
                    top: 8px;
                }
            }
        }
    }

    .control-col {
        &.main-input-col {
            .ant-input-password {
                border-radius: 25px;
                padding-left: 24px;
                padding-right: 24px;
                height: 40px;
                margin-top: 4px;

                input,
                .ant-input-suffix {
                    font-size: 16px;
                }

                @media (min-width: 992px) {
                    height: 50px;
                    margin-top: 0;

                    input,
                    .ant-input-suffix {
                        font-size: 18px;
                    }
                }
            }

            input.main-input {
                font-size: 16px;
                border-radius: 25px;
                padding-left: 24px;
                padding-right: 24px;
                height: 40px;
                margin-top: 4px;

                @media (min-width: 992px) {
                    height: 50px;
                    font-size: 18px;
                    margin-top: 0;
                }
            }

            .ant-form-item {
                &:not(.ant-form-item-has-error) {
                    input.main-input {
                        border-color: #707070;
                    }

                    span.ant-input-password {
                        border-color: #707070;
                    }
                }
            }
        }

        &.main-radio-col {
            div.radio-primary-wrapper {
                display: flex;
                align-items: center;

                .ant-radio-group {
                    display: flex;
                    align-items: center;

                    label {
                        font-size: 3.2vw;

                        @media (max-width: 419.98px) {
                            font-size: 13px;
                        }

                        @media (min-width: 650px) {
                            font-size: 20px;
                        }
                    }
                }

                .ant-form-item-explain.ant-form-item-explain-error {
                    position: relative;
                    top: -4px;
                }

                &.race {
                    .ant-form-item-explain.ant-form-item-explain-error {
                        @media (max-width: 767.98px) {
                            position: relative;
                            top: 2px;
                            padding-bottom: 8px;
                        }

                        @media (min-width: 768px) {
                            position: relative;
                            top: -8px;
                        }
                    }

                    .ant-form-item-has-error {
                        .race-input {
                            div.input-wrapper {
                                input.unspecified-error {
                                    border-color: #ff4d4f;
                                }
                            }
                        }
                    }
                }
            }

            .race-radio-group {
                width: 100%;

                .race-input {
                    display: flex;
                    align-items: center;
                    flex-grow: 100;

                    @media (max-width: 767.98px) {
                        flex-direction: column;
                        align-items: flex-start;
                    }

                    div.input-wrapper {
                        flex-grow: 100;

                        @media (max-width: 767.98px) {
                            padding-top: 12px;
                            width: 100%;
                        }

                        input {
                            font-size: 18px;
                            border-radius: 25px;
                            height: 40px;
                            border-color: #707070;
                            padding-left: 24px;
                            padding-right: 24px;
                        }
                    }
                }
            }

            .radio-group-wrapper {
                display: flex;
                align-items: center;
                width: 100%;

                @media (max-width: 767.98px) {
                    flex-direction: column;
                    align-items: flex-start;
                }

                .radio-option-wrapper {
                    @media (max-width: 767.98px) {
                        min-height: 30px;
                    }
                }
            }
        }
    }

    .form-header {
        color: #e03934;
        font-weight: bold;

        font-size: 5vw;
        margin-top: 6vw;

        @media (min-width: 768px) {
            font-size: 28px;
            margin-top: 50px;
        }
    }

    .form-header-separator {
        width: 100px;
        background-color: #e03934;
        height: 4px;
        margin: 12px auto 36px;
        border-radius: 30px;

        @media (max-width: 991.98px) {
            margin-bottom: 12px;
        }
    }

    .ant-radio-group {
        float: left;

        .race-custom-input {
            width: 120px;
            height: 50px;
            border-radius: 25px;
            padding-left: 24px;
            padding-right: 24px;
            font-size: 18px;
            margin-left: 8px;
        }
    }

    .ant-radio-inner {
        transform: scale(0.6);
        border-color: #707070;
    }

    .ant-radio-inner:after {
        background-color: #1aaac2;
        transform: scale(1.8);
    }

    font-size: 20px;

    position: relative;
    z-index: 1;

    div.bg {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;

        background-color: white;
        opacity: 0.85;
        z-index: -1;
    }

    div.content {
        z-index: 2;
    }

    button.submit {
        background-color: #e03934;
        color: white;
        outline: none;
        border: 2px solid #e03934;
        border-radius: 25px;
        text-transform: uppercase;
        cursor: pointer;
        font-size: 21px;
        font-weight: 900;
        height: 40px;

        margin-top: 20px;
        padding: 2px 20px;

        @media (max-width: 639.98px) {
            font-size: 18px;
            padding: 2px 10vw;
        }

        @media (min-width: 640px) {
            width: 234px;
        }

        /* width: min(234px, calc(100% - 24px)); */

        &:hover {
            background-color: #f0f4f9;
            color: #e03934;
        }

        &:focus {
            background-color: #e03934;
            color: white;
        }

        &.ant-btn-loading {
            border-width: 1px;
        }
    }

    div.back-to-top {
        margin-top: 60px;
        margin-bottom: 40px;

        display: flex;
        flex-direction: row-reverse;
    }

    .terms {
        .ant-checkbox-wrapper:hover .ant-checkbox-inner,
        .ant-checkbox:hover .ant-checkbox-inner,
        .ant-checkbox-input:focus + .ant-checkbox-inner,
        .ant-checkbox-checked .ant-checkbox-inner::after,
        .ant-checkbox-checked::after {
            border-color: #e03934;
        }

        .ant-checkbox-checked .ant-checkbox-inner {
            background-color: transparent;
            border-color: #e03934;
        }

        .ant-form-item-control {
            text-align: left;
        }
        .ant-checkbox-wrapper {
            font-size: 3.2vw;

            @media (max-width: 419.98px) {
                font-size: 13px;
            }

            @media (min-width: 650px) {
                font-size: 20px;
            }
        }

        button {
            padding-left: 0;
            padding-right: 0;
            font-size: 18px;
            color: #1aaac2;

            span {
                text-decoration: underline;
            }

            font-size: 3.2vw;

            @media (max-width: 419.98px) {
                font-size: 13px;
            }

            @media (min-width: 650px) {
                font-size: 20px;
            }
        }
    }

    .ant-form-item-explain.ant-form-item-explain-error {
        text-align: left;
        margin-left: 4px;
        margin-top: 4px;
    }
`;
