import styled from 'styled-components';
import { Row } from 'antd';

export const HowToParticipateContainer = styled.div`
    .how-header {
        font-size: 4vw;
        font-weight: 900;
        text-transform: uppercase;
        text-align: center;
        color: #e03934;

        position: relative;
        top: 4vw;

        @media (min-width: 1140px) {
            font-size: 45px;
        }

        @media (min-width: 1400px) {
            top: 6vw;
        }
    }

    .register-anchor {
        position: relative;
        bottom: 5vw;

        @media (min-width: 1400px) {
            bottom: 4.7vw;
        }

        button {
            cursor: pointer;
            font-size: 1.2vw;
            font-weight: 900;

            padding: 0 3vw;

            border-radius: 20px;
            border: 2px solid #e03934;
            outline: none;

            background-color: #e03934;
            color: #f0f4f9;

            transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);

            &:hover {
                background-color: #f0f4f9;
                color: #e03934;
            }

            @media (min-width: 1700px) {
                font-size: 21px;
                padding: 0 45px;
            }
        }
    }
`;

export const HowToParticipateRow = styled(Row)`
    max-width: 1200px;
    margin: 0 auto 0;

    margin-top: 80px;

    @media (min-width: 1919.98px) {
        margin-top: 70px;
    }

    img.participate-step {
        width: 310px;
    }

    button.register {
        margin: 20px auto 40px;

        @media (min-width: 1919.98px) {
            margin-top: 30px;
        }

        cursor: pointer;
    }
`;
