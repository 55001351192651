import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { TimerWrapper, Timer, LongRegisterButton } from './styled';

const padStartZeros = (num) => {
    let result = num.toString();
    while (result.length < 2) result = '0' + result;
    return result;
};

const EVENT_START_TIME = {
    year: 2021,
    month: 11,
    day: 9,
    hour: 10,
    minute: 0
};

const CountdownTimer = () => {
    const calculateTimeLeft = () => {
        const { year, month, day, hour, minute } = EVENT_START_TIME;
        const difference = +new Date(year, month - 1, day, hour, minute, 0, 0) - +new Date();
        let timeLeft = {};

        if (difference > 0) {
            timeLeft = {
                days: Math.floor(difference / (1000 * 60 * 60 * 24)),
                hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
                minutes: Math.floor((difference / 1000 / 60) % 60),
                seconds: Math.floor((difference / 1000) % 60)
            };
        }

        return timeLeft;
    };

    const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

    useEffect(() => {
        const timer = () =>
            setTimeout(() => {
                setTimeLeft(calculateTimeLeft());
            }, 1000);

        const timerId = timer();

        return () => {
            clearTimeout(timerId);
        };
    });

    const timerComponents = [];

    Object.keys(timeLeft).forEach((interval) => {
        if (isNaN(timeLeft[interval])) {
            return;
        }

        if (timerComponents.length) {
            timerComponents.push(
                <div className="time-separator" key={`time-separator-${interval}`}>
                    :
                </div>
            );
        }

        timerComponents.push(
            <div className="time-card" key={interval}>
                <h2 className="time-digit">{padStartZeros(timeLeft[interval])}</h2>
                <span className="time-interval">{interval}</span>
            </div>
        );
    });

    const eventStarted = !timerComponents.length;

    if (eventStarted) {
        return null;
    }

    return (
        <TimerWrapper>
            <Link to="/register">
                <LongRegisterButton type="primary" danger size="large">
                    Register now to win goodies
                </LongRegisterButton>
            </Link>

            <Timer>{timerComponents?.length && timerComponents}</Timer>
        </TimerWrapper>
    );
};

export default CountdownTimer;
