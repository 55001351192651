import styled from 'styled-components';

export const LandingStyles = styled.div`
    .back-to-top {
        max-width: 1200px;

        padding: 0 36px;

        margin: 80px auto 46px;

        @media (min-width: 1200px) {
            margin: 120px auto 46px;
        }

        @media (max-width: 767.98px) {
            margin-top: 48px;
            margin-bottom: 24px;
        }

        display: flex;
        flex-direction: row-reverse;
    }
`;
