import styled from 'styled-components';
import { HashLink } from 'react-router-hash-link';

export const PageHeaderStyle = styled.div`
    background-color: #f0f4f9;

    div.nav {
        max-width: 1200px;
        margin: 0 auto;

        display: none;
        align-items: center;
        justify-content: space-evenly;

        img.logo {
            width: 12vw;
            max-width: 172px;
            margin: 20px;
            margin-right: 30px;
            height: auto;
        }

        @media (min-width: 768px) {
            display: flex;
        }
    }

    div.mobile-nav {
        padding: 0 12px;

        @media (min-width: 768px) {
            display: none;
        }

        display: flex;
        align-items: center;
        justify-content: space-between;

        img.logo {
            max-width: 75px;
            width: 25%;
            margin: 12px;
            height: auto;
        }
    }
`;

export const HeaderLink = styled(HashLink)`
    color: #002147;

    font-size: 20px;

    font-weight: bold;

    border: 1px solid transparent;
    border-radius: 20px;
    padding: 2px 14px;

    @media (max-width: 1023.98px) {
        font-size: 18px;
    }

    &:hover {
        color: #1aaac2;
        border: 1px solid #1aaac2;
        transition: border 0.2s ease-in;
    }
`;

export const Overlay = styled.div`
    height: 100%;
    width: ${(props) => (props.showOverlay ? '100%' : '0%')};
    position: fixed;
    z-index: 25;
    left: 0;
    top: 0;
    background-color: rgb(0, 0, 0); /* Black fallback color */
    background-color: rgba(0, 0, 0, 0.9);
    overflow-x: hidden; /* Disable horizontal scroll */
    transition: 0.3s;

    .close {
        position: absolute;
        top: 18px;
        right: 12px;
        color: #818181;

        &:hover {
            color: #1aaac2;
            transition: border 0.2s ease-in;
        }
    }

    a {
        padding: 8px;
        text-decoration: none;
        font-size: 30px;

        color: ${(props) => (props.showOverlay ? '#818181' : 'rgba(0, 0, 0, 0.9)')};

        display: block;
        transition: 0.2s;

        &:hover {
            color: #1aaac2;
            transition: border 0.2s ease-in;
        }
    }

    .content {
        display: flex;
        flex-direction: column;
        align-items: center;

        position: relative;
        top: 30%;
        transition: 0.3s;
    }
`;
