import styled from 'styled-components';
import { HashLink } from 'react-router-hash-link';

export const StyledHashLink = styled(HashLink)`
    color: #e03934;
    font-size: 18px;
    font-weight: bold;
    border-radius: 20px;
    border: 2px solid transparent;
    padding: 4px 20px;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);

    display: flex;
    align-items: center;

    @media (max-width: 767.98px) {
        font-size: 14px;
    }

    img {
        margin-right: 10px;
        max-width: 27px;

        @media (max-width: 767.98px) {
            max-width: 20px;
        }
    }

    &:hover {
        border: 2px solid #e03934;
        color: #e03934;
    }
`;
